(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("SplicingMapController", SplicingMapController);

    SplicingMapController.$inject = [
        "$scope",
        "entity",
        "getJkDetails",
        "getSpliceMap",
        "getCableDetails",
        "getTubeDetails",
        "getCoreDetails",
        "postCoreFwdBwdPath",
        "$state",
        "AssetByID"
    ];

    function SplicingMapController(
        $scope,
        entity,
        getJkDetails,
        getSpliceMap,
        getCableDetails,
        getTubeDetails,
        getCoreDetails,
        postCoreFwdBwdPath,
        $state,
        AssetByID
    ) {
        var vm = this;
        vm.height = 1700;
        var linksList = []
        var infoWindow = new google.maps.InfoWindow();
        function callCableDetails(name){
            linksList.length = 0
            getCableDetails.get(name,function(cableResponse){ 
                var index = 0;
                vm.coreResponseStore = []
                vm.tubeResponseStore = []
                vm.coreNameList = []
                function processNextItem(){
                    if( index < cableResponse.length){
                        if(index == 0){ 
                            vm.cableName = cableResponse[index].name
                            getTubeDetails.get(cableResponse[index].name,function(tubeResponse){
                                tubeResponse.forEach(function(eachTube){
                                    vm.tubeResponseStore.push(eachTube)
                                    //linksList.push({source  : cableResponse[index].name , target : eachTube.name , value : "1" })
                                    getCoreDetails.get(eachTube.name,function(coreResponse){
                                        coreResponse.forEach(function(eachCore){
                                            vm.coreResponseStore.push(eachCore)
                                            vm.coreNameList.push(eachCore.name)
                                            linksList.push({source  : eachTube.name , target : eachCore.name , value : "1" })
                                        })
                                    })
                                })
                                index++;
                                processNextItem();
                            })
                        }
                    
                        if(index != 0){ 
                            getTubeDetails.get(cableResponse[index].name,function(tubeResponse){
                                tubeResponse.forEach(function(eachTube){
                                    vm.tubeResponseStore.push(eachTube)
                                    //linksList.push({source  : eachTube.name , target : cableResponse[index].name , value : "1"})
                                    getCoreDetails.get(eachTube.name,function(coreResponse){
                                        coreResponse.forEach(function(eachCore){
                                            vm.coreResponseStore.push(eachCore)
                                            vm.coreNameList.push(eachCore.name)
                                            linksList.push({source  : eachCore.name , target : eachTube.name , value : "1"})
                                        })
                                    })
                                })
                                index++;
                                processNextItem();
                            })
                        }
                    }else{
                        callSpliceMap()
                    }
                }
                processNextItem()
            })
        }

        getJkDetails.get(entity.id, function (jkResponse) {

            vm.jkResponse = jkResponse

            var filteredElement = jkResponse.filter(function(item) {
                return item.name == entity.data;
              }); 
              
            vm.jkDetails = filteredElement[0];
            vm.selectedJKName = filteredElement[0].name
            callCableDetails(vm.selectedJKName)
        }); 

        vm.onJKChange = function(){
            $state.go('asset.splicing',{id: entity.id,data: vm.selectedJKName})
        }

        function callSpliceMap(){
            getSpliceMap.get(vm.jkDetails, function (connectedCore) {
                if(connectedCore[0].coreTuplelist){
                    connectedCore[0].coreTuplelist.forEach(function(eachSplice){
                        if((eachSplice.coreASide.name.includes(vm.cableName)) && (eachSplice.coreBSide.name && eachSplice.coreASide.name) ){
                            linksList.push({source  : eachSplice.coreASide.name , target : eachSplice.coreBSide.name , value : "1"})
                        }else{
                            linksList.push({source  : eachSplice.coreBSide.name , target : eachSplice.coreASide.name , value : "1"})
                        }
                      }) 
                }
                
                
                if(linksList.length <= 50){
                    vm.height = 400
                    }else if(linksList.length >= 50 && linksList.length <= 100) {
                        vm.height = 800
                    }else if(linksList.length >= 100 && linksList.length <= 150){
                        vm.height = 1200
                    }else{
                        vm.height = 1600
                    }
                drawSplicingMap();
            });
        }
         
        var sankey,data, filteredArrayCore,cableLength = 0;
        function drawSplicingMap() {

            var margin = { top: 10, right: 10, bottom: 10, left: 10 },
            width = 1050 - margin.left - margin.right,
            height = vm.height - margin.top - margin.bottom;

        var formatNumber = d3.format(",.0f"), // zero decimal places
            format = function (d) {
                return formatNumber(d) + " ";
            },
            color = d3.scale.category20();

        // append the svg canvas to the page
        var svg = d3
            .select("#splicingMap")
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr(
                "transform",
                "translate(" + margin.left + "," + margin.top + ")"
            );

        // Set the sankey diagram properties
        sankey = sankey()
            .nodeWidth(36)
            .nodePadding(10)
            .size([width, height]);

        var path = sankey.link();
        data = linksList
            // Filter the jsonData array to include only unique combinations
            var uniqueCombinations = new Set();
            var filteredData = linksList.filter(function (item) {
                var source = item.source;
                var target = item.target;
                var combination = source + "-" + target;

                if (uniqueCombinations.has(combination)) {
                    return false; // Duplicate combination, exclude from filtered data
                } else {
                    uniqueCombinations.add(combination);
                    return true; // Unique combination, include in filtered data
                }
            });

            var uniqueNodes = {};

            linksList.forEach(function (item) {
                var source = item.source;
                var target = item.target;
                uniqueNodes[source] = true;
                uniqueNodes[target] = true;
            });

            var uniqueNodesLat = Object.keys(uniqueNodes).map(function (node) {
                return { name: node };
            }); 
            
            /* var colorMap = {};

            var colors = [
                "Blue",
                "Orange",
                "Green",
                "Brown",
                "#71797E",
                "White",
                "Red",
                "Black",
                "Yellow",
                "Violet",
                "Rose",
                "Aqua",
            ];

            // Generate color map for Tube A and B
            for (var i = 1; i <= 8; i++) {
                colorMap["TubeA_Tube" + i] = colors[i - 1];
                colorMap["TubeB_Tube" + i] = colors[i - 1];
            }
            var corePrefixA = "A_";
            var corePrefixB = "B_";
            for (var i = 1; i <= 8; i++) {
                for (var j = 1; j <= 12; j++) {
                    var keyA = corePrefixA + i + "_C" + j;
                    var colorIndexA = (i - 1) * 12 + (j - 1); // Calculate a unique color index
                    colorMap[keyA] = colors[colorIndexA % colors.length];

                    var keyB = corePrefixB + i + "_C" + j;
                    var colorIndexB = (i - 1) * 12 + (j - 1); // Calculate a unique color index
                    colorMap[keyB] = colors[colorIndexB % colors.length];
                }
            } */
            
            var graph = {
                links: filteredData,
                nodes: uniqueNodesLat,
            };

            var nodeMap = {};
            graph.nodes.forEach(function (x) {
                nodeMap[x.name] = x;
            });
            graph.links = graph.links.map(function (x) {
                return {
                    source: nodeMap[x.source],
                    target: nodeMap[x.target],
                    value: x.value,
                };
            });

            sankey.nodes(graph.nodes).links(graph.links).layout(32);

            // add in the links

            var link = svg
                .append("g")
                .selectAll(".link")
                .data(graph.links)
                .enter()
                .append("path")
                .attr("class", "link")
                .attr("d", path)
                /* .style("stroke", function (d) {
                    if (
                        d.target.name == "TubeB_Tube1" ||
                        d.target.name == "TubeB_Tube2" ||
                        d.target.name == "TubeB_Tube3" ||
                        d.target.name == "TubeB_Tube4" ||
                        d.target.name == "TubeB_Tube5" ||
                        d.target.name == "TubeB_Tube6" ||
                        d.target.name == "TubeB_Tube7" ||
                        d.target.name == "TubeB_Tube8"
                    ) {
                        return colorMap[d.target.name];
                    }
                    return colorMap[d.source.name];
                }) */
                .style("stroke-width", function (d) {
                    return 4;
                })
                .sort(function (a, b) {
                    return b.dy - a.dy;
                });

            // add the link titles
            link.append("title").text(function (d) {
                if (d.target) {
                    return d.source.name + " → " + d.target.name + "\n";
                }
            });

            var colorCodeNodesCore = extractColorCodeNodes(vm.coreResponseStore);
            var colorCodeNodesTube = extractColorCodeNodes(vm.tubeResponseStore);
            
            var combinedColorCodeNodes = colorCodeNodesCore.concat(colorCodeNodesTube);
            
            // add in the nodes
            var node = svg
                .append("g")
                .selectAll(".node")
                .data(graph.nodes)
                .enter()
                .append("g")
                .attr("class", "node")
                .attr("transform", function (d) {
                    return "translate(" + d.x + "," + d.y + ")";
                });
            /* .call(d3.behavior.drag()
          .origin(function(d) { return d; })
          .on("dragstart", function() { 
              this.parentNode.appendChild(this); })
          .on("drag", dragmove)); */

            // add the rectangles for the nodes
            node.append("rect")
                .attr("height", function (d) {
                    return d.dy;
                })
                .attr("width", sankey.nodeWidth())
                .style("fill", function (d) {
                    var color
                    for (var i = 0; i < combinedColorCodeNodes .length; i++) {
                        if (combinedColorCodeNodes [i].hasOwnProperty(d.name)) {
                            color = combinedColorCodeNodes [i][d.name];
                            break;
                        }
                    }
                    return color
                    //return  color(d.name.replace(/ .*/, ""));
                })
                .style("stroke", function (d) {
                    return d3.rgb(d.color).darker(2)
                })
                .append("title")
                .text(function (d) {
                        return d.name + "\n";
                });

            // add in the title for the nodes
            node.append("text")
                .attr("x", -6)
                .attr("y", function (d) {
                    return d.dy / 2;
                })
                .attr("dy", ".35em")
                .attr("text-anchor", "end")
                .attr("transform", null)
                .text(function (d) {
                        return d.name + "\n";
                })
                .filter(function (d) {
                    return d.x < width / 2;
                })
                .attr("x", 6 + sankey.nodeWidth())
                .attr("text-anchor", "start");

            node.on("click", function (getName) {
                vm.cableLength = 0
                filteredArrayCore = null
                vm.displayFiberPathName = null
                if(vm.coreNameList.includes(getName.name)){
                    map = new google.maps.Map(
                        document.getElementById("map"),
                        myOption(12, {
                            lat: 12.98330744645683,
                            lng: 77.56018512933986,
                        })
                    );

                    for (var i = 0; i < vm.coreResponseStore.length; i++) {
                        if (vm.coreResponseStore[i].name == getName.name) {
                            filteredArrayCore = vm.coreResponseStore[i];
                            break;
                        }
                    }
                    
                 vm.displayFiberPathName = filteredArrayCore.name
                 $scope.$apply()
                 $("#showPathModal").show();
                }
                
            });
        }
        // the function for moving the nodes
        /* function dragmove(d) {
        d3.select(this).attr("transform", 
            "translate(" + (
                   d.x = Math.max(0, Math.min(width - d.dx, d3.event.x))
                ) + "," + (
                       d.y = Math.max(0, Math.min(height - d.dy, d3.event.y))
                ) + ")");
        sankey.relayout();
        link.attr("d", path);
      } */
        vm.closeAssetMarkerModal = function () {
            $(":file").filestyle("clear");
            $("#showPathModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#showPathModal").hide();
        };

        var map;
        vm.showFwdPath = function() {
            vm.jkFwdPath = null
            vm.cableFwdPath = null
            if (filteredArrayCore != null) {
                postCoreFwdBwdPath.getJkFwd(
                    filteredArrayCore,
                    function (response) {
                        vm.jkFwdPath = response;
                        if(vm.jkFwdPath != null && vm.jkFwdPath.length >=1){
                          showCableFwd();
                        }else{
                            alert("*No Forward Path Available")
                        }
                    }
                );
            }
        };

        function showCableFwd(){
            postCoreFwdBwdPath.getCableFwd(
                filteredArrayCore,
                function (response) {
                    vm.cableFwdPath = response;
                    if(vm.cableFwdPath != null && vm.cableFwdPath.length >=1){
                        showFwd();
                    }
                    
                }
            );
        }

        vm.showBwdPath = function() { 
            vm.cableBwdPath = null
            vm.jkBwdPath = null
            if (filteredArrayCore  != null) {
                postCoreFwdBwdPath.getJkBwd(
                    filteredArrayCore,
                    function (response) {
                        vm.jkBwdPath = response;
                        if(vm.jkBwdPath != null && vm.jkBwdPath.length >= 1){
                            showCableBwd();
                        }else{
                            alert("*No Backward Path Available")
                        }
                        
                    }
                );
                
            }
        }

        function showCableBwd(){ 
            postCoreFwdBwdPath.getCableBwd(
                filteredArrayCore,
                function (response) {
                    vm.cableBwdPath = response;
                    if(vm.cableBwdPath != null && vm.cableBwdPath.length >= 1){
                        showBwd();
                    }
                    
                }
            );
        }

        function showFwd() {
            cableLength = 0
            vm.cableLength = 0
            map = new google.maps.Map(
                document.getElementById("map"),
                myOption(12, {
                   lat: vm.jkFwdPath[0].assetCoordinate.latitude,
                    lng: vm.jkFwdPath[0].assetCoordinate.longitude,
                })
            );

            var cableCoordinates = [];
            
            vm.cableFwdPath.forEach(function (each){
                cableCoordinates = [];
                cableLength  += each.length;
                var coord = each.assetCoordinates;
                coord.forEach(function (item) {
                    cableCoordinates.push({
                        lat: item.latitude,
                        lng: item.longitude,
                    });
                });

                var cablePath = new google.maps.Polyline({
                    path: cableCoordinates,
                    strokeColor: "#fd7a24",
                    strokeOpacity: 1,
                    strokeWeight: 5,
                });
    
                cablePath.setMap(map);

                cablePath.addListener('click', function (event) {
                    closeInfoWindows();
                    AssetByID.get(each.spreadAssetId, function (response) { 
                        infoWindow.setContent('<div class="infoWindowhead"> <b> Cable  Name : ' +
                        each.name +
                    ' </b></div><div class="infoWindowContent">' +
                    "<b>Asset Name : </b>" + response.name + "<br><b>Asset Type : </b>"+
                    response.assetTypeName + "</div>");
                    infoWindow.setPosition(event.latLng);
                    infoWindow.open(map, cablePath);
                    }) 
                });
            }) 
            if(cableLength >= 1){
                vm.cableLength = cableLength.toFixed(2)
            }
            
            vm.jkFwdPath.forEach(function (each){
                var coord = each;
                if(each.name == vm.selectedJKName) {
                    
                    var marker = new google.maps.Marker({
                        icon:  {
                            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            strokeColor: "#04B907",
                            fillColor:'#04B907',
                            fillOpacity:1,
                            scale: 4,
                            labelOrigin: new google.maps.Point(-1, 7)
                        },
                        map: map,
                        position: new google.maps.LatLng(
                            coord.assetCoordinate.latitude,
                            coord.assetCoordinate.longitude
                        ),
                        label: {
                            text:"Start",
                            fontSize: '15px',
                            fontWeight: 'bold'
                          }
                    });
                    marker.setMap(map)
                }
                

                
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(
                        coord.assetCoordinate.latitude,
                        coord.assetCoordinate.longitude
                    ),
                    icon: {
                        url: "content/images/Chamber.png",
                        scaledSize: new google.maps.Size(24, 24),
                        anchor: new google.maps.Point(
                            24 / 2,
                            24 / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                    map: map,
                });

                marker.addListener('click', function () {
                    closeInfoWindows();
                    AssetByID.get(each.fixedAssetId, function (response) {
                        infoWindow.setContent('<div class="infoWindowhead"> <b> JK  Name : ' +
                        each.name +
                    ' </b></div><div class="infoWindowContent">' +
                    "<b>Asset Name : </b>" + response.name + "<br><b>Asset Type : </b>"+
                    response.assetTypeName + "<br><br><b>Latitude</b> :" +
                    each.assetCoordinate.latitude +
                    "<br><b>Longitude</b> :" +
                    each.assetCoordinate.longitude +
                    "<br></div>");
                    infoWindow.open(map, marker);
                    })
                });
            })
        }

        function showBwd() {
            cableLength = 0
            vm.cableLength = 0
            map = new google.maps.Map(
                document.getElementById("map"),
                myOption(12, {
                    lat: vm.jkBwdPath[0].assetCoordinate.latitude,
                    lng: vm.jkBwdPath[0].assetCoordinate.longitude,
                })
            );

            var cableCoordinates = [];
            vm.cableBwdPath.forEach(function (each){
                cableCoordinates = [];
                cableLength  += each.length;
                var coord = each.assetCoordinates;
                coord.forEach(function (item) {
                    cableCoordinates.push({
                        lat: item.latitude,
                        lng: item.longitude,
                    });
                });

                var cablePath = new google.maps.Polyline({
                    path: cableCoordinates,
                    strokeColor: "#fd7a24",
                    strokeOpacity: 1,
                    strokeWeight: 5,
                });
    
                cablePath.setMap(map);

                cablePath.addListener('click', function (event) {
                    closeInfoWindows();
                    AssetByID.get(each.spreadAssetId, function (response) { 
                        infoWindow.setContent('<div class="infoWindowhead"> <b> Cable Name : ' +
                        each.name +
                    ' </b></div><div class="infoWindowContent">' +
                    "<b>Name : </b>" + response.name + "<br></div>");
                    infoWindow.setPosition(event.latLng);
                    infoWindow.open(map, cablePath);
                    }) 
                });
            }) 
            if(cableLength >= 1){
                vm.cableLength = cableLength.toFixed(2)
            }
            
            vm.jkBwdPath.forEach(function (each){
                var coord = each;
                if(each.name == vm.selectedJKName) {
                    
                    var marker = new google.maps.Marker({
                        icon:  {
                            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            strokeColor: "#04B907",
                            fillColor:'#04B907',
                            fillOpacity:1,
                            scale: 4,
                            labelOrigin: new google.maps.Point(-1, 7)
                        },
                        map: map,
                        position: new google.maps.LatLng(
                            coord.assetCoordinate.latitude,
                            coord.assetCoordinate.longitude
                        ),
                        label: {
                            text:"Start",
                            fontSize: '15px',
                            fontWeight: 'bold'
                          }
                    });
                    marker.setMap(map)
                }

                
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(
                        coord.assetCoordinate.latitude,
                        coord.assetCoordinate.longitude
                    ),
                    icon: {
                        url: "content/images/Chamber.png",
                        scaledSize: new google.maps.Size(24, 24),
                        anchor: new google.maps.Point(
                            24 / 2,
                            24 / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                    title:coord.name,
                    map: map,
                });

                marker.addListener('click', function () {
                    closeInfoWindows();
                    AssetByID.get(each.fixedAssetId, function (response) {
                        infoWindow.setContent('<div class="infoWindowhead"> <b> FDMS  Name : ' +
                        each.name +
                    ' </b></div><div class="infoWindowContent">' +
                    "<b>Name : </b>" + response.name + "<br><br><b>Latitude</b> :" +
                    each.assetCoordinate.latitude +
                    "<br><b>Longitude</b> :" +
                    each.assetCoordinate.longitude +
                    "<br></div>");
                    infoWindow.open(map, marker);
                    })
                });
            })
        }

        function extractColorCodeNodes(data) {
            return data.map(function(link) {
                var obj = {};
                obj[link.name] = '#' + link.colorCodeHex;
                return obj;
            });
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    } /* 
              {
                  elementType: "geometry.fill",
                  stylers: [
                      {
                          color: "#EFEFEF",
                      },
                  ],
              }, */,
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "landscape",
                        stylers: [
                            {
                                color: "#efefef",
                            },
                        ],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#ffffff",
                            },
                        ],
                    },
                ],
            });
        }

          // Function to close all info windows
  function closeInfoWindows() {
    infoWindow.close();
  }

        function sankey() {
            var sankey = {},
                nodeWidth = 24,
                nodePadding = 8,
                size = [1, 1],
                nodes = [],
                links = [];

            sankey.nodeWidth = function (_) {
                if (!arguments.length) return nodeWidth;
                nodeWidth = +_;
                return sankey;
            };

            sankey.nodePadding = function (_) {
                if (!arguments.length) return nodePadding;
                nodePadding = +_;
                return sankey;
            };

            sankey.nodes = function (_) {
                if (!arguments.length) return nodes;
                nodes = _;
                return sankey;
            };

            sankey.links = function (_) {
                if (!arguments.length) return links;
                links = _;
                return sankey;
            };

            sankey.size = function (_) {
                if (!arguments.length) return size;
                size = _;
                return sankey;
            };

            sankey.layout = function (iterations) {
                computeNodeLinks();
                computeNodeValues();
                computeNodeBreadths();
                computeNodeDepths(iterations);
                computeLinkDepths();
                return sankey;
            };

            sankey.relayout = function () {
                computeLinkDepths();
                return sankey;
            };

            sankey.link = function () {
                var curvature = 0.5;

                function link(d) {
                    if (d.target) {
                        var x0 = d.source.x + d.source.dx,
                            x1 = d.target.x,
                            xi = d3.interpolateNumber(x0, x1),
                            x2 = xi(curvature),
                            x3 = xi(1 - curvature),
                            y0 = d.source.y + d.sy + d.dy / 2,
                            y1 = d.target.y + d.ty + d.dy / 2;
                        return (
                            "M" +
                            x0 +
                            "," +
                            y0 +
                            "C" +
                            x2 +
                            "," +
                            y0 +
                            " " +
                            x3 +
                            "," +
                            y1 +
                            " " +
                            x1 +
                            "," +
                            y1
                        );
                    }
                }

                link.curvature = function (_) {
                    if (!arguments.length) return curvature;
                    curvature = +_;
                    return link;
                };

                return link;
            };

            // Populate the sourceLinks and targetLinks for each node.
            // Also, if the source and target are not objects, assume they are indices.
            function computeNodeLinks() {
                nodes.forEach(function (node) {
                    node.sourceLinks = [];
                    node.targetLinks = [];
                });
                links.forEach(function (link) {
                    var source = link.source,
                        target = link.target;
                    if (source.sourceLinks && target) {
                        if (typeof source === "number")
                            source = link.source = nodes[link.source];
                        if (typeof target === "number")
                            target = link.target = nodes[link.target];
                        source.sourceLinks.push(link);
                        target.targetLinks.push(link);
                    }
                });
            }

            // Compute the value (size) of each node by summing the associated links.
            function computeNodeValues() {
                nodes.forEach(function (node) {
                    node.value = Math.max(
                        d3.sum(node.sourceLinks, value),
                        d3.sum(node.targetLinks, value)
                    );
                });
            }

            // Iteratively assign the breadth (x-position) for each node.
            // Nodes are assigned the maximum breadth of incoming neighbors plus one;
            // nodes with no incoming links are assigned breadth zero, while
            // nodes with no outgoing links are assigned the maximum breadth.
            function computeNodeBreadths() {
                var remainingNodes = nodes,
                    nextNodes,
                    x = 0;

                while (remainingNodes.length) {
                    nextNodes = [];
                    remainingNodes.forEach(function (node) {
                        node.x = x;
                        node.dx = nodeWidth;
                        node.sourceLinks.forEach(function (link) {
                            nextNodes.push(link.target);
                        });
                    });
                    remainingNodes = nextNodes;
                    ++x;
                }

                //
                moveSinksRight(x);
                scaleNodeBreadths((size[0] - nodeWidth) / (x - 1));
            }

            function moveSourcesRight() {
                nodes.forEach(function (node) {
                    if (!node.targetLinks.length) {
                        node.x =
                            d3.min(node.sourceLinks, function (d) {
                                return d.target.x;
                            }) - 1;
                    }
                });
            }

            function moveSinksRight(x) {
                nodes.forEach(function (node) {
                    if (!node.sourceLinks.length) {
                        node.x = x - 1;
                    }
                });
            }

            function scaleNodeBreadths(kx) {
                nodes.forEach(function (node) {
                    node.x *= kx;
                });
            }

            function computeNodeDepths(iterations) {
                var nodesByBreadth = d3
                    .nest()
                    .key(function (d) {
                        return d.x;
                    })
                    .sortKeys(d3.ascending)
                    .entries(nodes)
                    .map(function (d) {
                        return d.values;
                    });

                //
                initializeNodeDepth();
                resolveCollisions();
                for (var alpha = 1; iterations > 0; --iterations) {
                    relaxRightToLeft((alpha *= 0.99));
                    resolveCollisions();
                    relaxLeftToRight(alpha);
                    resolveCollisions();
                }

                function initializeNodeDepth() {
                    var ky = d3.min(nodesByBreadth, function (nodes) {
                        return (
                            (size[1] - (nodes.length - 1) * nodePadding) /
                            d3.sum(nodes, value)
                        );
                    });

                    nodesByBreadth.forEach(function (nodes) {
                        nodes.forEach(function (node, i) {
                            node.y = i;
                            node.dy = node.value * ky;
                        });
                    });

                    links.forEach(function (link) {
                        link.dy = link.value * ky;
                    });
                }

                function relaxLeftToRight(alpha) {
                    nodesByBreadth.forEach(function (nodes, breadth) {
                        nodes.forEach(function (node) {
                            if (node.targetLinks.length) {
                                var y =
                                    d3.sum(node.targetLinks, weightedSource) /
                                    d3.sum(node.targetLinks, value);
                                node.y += (y - center(node)) * alpha;
                            }
                        });
                    });

                    function weightedSource(link) {
                        return center(link.source) * link.value;
                    }
                }

                function relaxRightToLeft(alpha) {
                    nodesByBreadth
                        .slice()
                        .reverse()
                        .forEach(function (nodes) {
                            nodes.forEach(function (node) {
                                if (node.sourceLinks.length) {
                                    var y =
                                        d3.sum(
                                            node.sourceLinks,
                                            weightedTarget
                                        ) / d3.sum(node.sourceLinks, value);
                                    node.y += (y - center(node)) * alpha;
                                }
                            });
                        });

                    function weightedTarget(link) {
                        return center(link.target) * link.value;
                    }
                }

                function resolveCollisions() {
                    nodesByBreadth.forEach(function (nodes) {
                        var node,
                            dy,
                            y0 = 0,
                            n = nodes.length,
                            i;

                        // Push any overlapping nodes down.
                        nodes.sort(ascendingDepth);
                        for (i = 0; i < n; ++i) {
                            node = nodes[i];
                            dy = y0 - node.y;
                            if (dy > 0) node.y += dy;
                            y0 = node.y + node.dy + nodePadding;
                        }

                        // If the bottommost node goes outside the bounds, push it back up.
                        dy = y0 - nodePadding - size[1];
                        if (dy > 0) {
                            y0 = node.y -= dy;

                            // Push any overlapping nodes back up.
                            for (i = n - 2; i >= 0; --i) {
                                node = nodes[i];
                                dy = node.y + node.dy + nodePadding - y0;
                                if (dy > 0) node.y -= dy;
                                y0 = node.y;
                            }
                        }
                    });
                }

                function ascendingDepth(a, b) {
                    return a.y - b.y;
                }
            }

            function computeLinkDepths() {
                nodes.forEach(function (node) {
                    node.sourceLinks.sort(ascendingTargetDepth);
                    node.targetLinks.sort(ascendingSourceDepth);
                });
                nodes.forEach(function (node) {
                    var sy = 0,
                        ty = 0;
                    node.sourceLinks.forEach(function (link) {
                        link.sy = sy;
                        sy += link.dy;
                    });
                    node.targetLinks.forEach(function (link) {
                        link.ty = ty;
                        ty += link.dy;
                    });
                });

                function ascendingSourceDepth(a, b) {
                    return a.source.y - b.source.y;
                }

                function ascendingTargetDepth(a, b) {
                    return a.target.y - b.target.y;
                }
            }

            function center(node) {
                return node.y + node.dy / 2;
            }

            function value(link) {
                return link.value;
            }

            return sankey;
        }
    }
})();